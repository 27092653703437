import { GatsbyBrowser } from 'gatsby';

declare function i3(x: string, y?: string, z?: string): any;

declare const window: Window['window'] & {
  dataLayer: Record<string, unknown>[];
  dmm_mkbase_ga_util: {
    merge_common_data: (data: object) => any;
  };
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  location,
  prevLocation
}) => {
  const sendI3 = () => {
    if (
      (typeof window !== 'undefined' &&
        typeof document !== 'undefined' &&
        process.env.GATSBY_ENV === 'production') ||
      process.env.GATSBY_ENV === 'staging'
    ) {
      const data = {
        event: 'page_view',
        cloud_retail: {
          eventType: 'home-page-view'
        },
        manual_fire_event: true
      };
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push(window.dmm_mkbase_ga_util.merge_common_data(data));
    }
  };

  if (prevLocation) {
    sendI3();
  }

  // FIXME: コンタクトにアクセスするたびスクリプトタグが増えるのでなんとかしたい
  if (location.pathname.includes('contact')) {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = 'https://sdk.form.run/js/v2/embed.js';
      document.body.appendChild(script);
    }
  }
};
