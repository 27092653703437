exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-member-index-tsx": () => import("./../../../src/pages/company/member/index.tsx" /* webpackChunkName: "component---src-pages-company-member-index-tsx" */),
  "component---src-pages-company-profile-index-tsx": () => import("./../../../src/pages/company/profile/index.tsx" /* webpackChunkName: "component---src-pages-company-profile-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thanks-index-tsx": () => import("./../../../src/pages/contact/thanks/index.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-draft-index-tsx": () => import("./../../../src/pages/news/draft/index.tsx" /* webpackChunkName: "component---src-pages-news-draft-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-templates-news-page-category-template-tsx": () => import("./../../../src/templates/NewsPageCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-news-page-category-template-tsx" */),
  "component---src-templates-news-page-template-tsx": () => import("./../../../src/templates/NewsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-news-page-template-tsx" */),
  "component---src-templates-news-page-year-template-tsx": () => import("./../../../src/templates/NewsPageYearTemplate.tsx" /* webpackChunkName: "component---src-templates-news-page-year-template-tsx" */),
  "component---src-templates-news-single-page-template-tsx": () => import("./../../../src/templates/NewsSinglePageTemplate.tsx" /* webpackChunkName: "component---src-templates-news-single-page-template-tsx" */)
}

